<template>
  <div class="text-center">
    <v-dialog id="cookie-modal-dialog" v-model="dialog" width="500" persistent v-if="!isCookieAccepted()">
      <v-card id="cookie-modal-card" class="dialog-style">
        <v-card-title id="text-cookie-card-title" class="text-h5 tfe-primary-bg white--text">
          Use of cookies
        </v-card-title>
        <v-card-text id="text-cookie-card-text" class="pt-4 cookies-text">
          This website uses cookies. You accept the treatment of
          cookies for the purpose of improving the application.
          If you agree to the processing of your data, click accept.
          <a :href='getCookiesUrl()' target="_blank" >More info</a>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-switch v-model="switch1" readonly inset label="Mandatory cookies"></v-switch>
          <v-spacer></v-spacer>
          <v-btn outlined rounded id="accept-cookie-btn" class="accept-btn" @click="acceptCookie()">
            Accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { environmentService } from '@/services/environmentService';
import cookieManager from '@/store/cookieManager';

export default {
  name: 'Cookies',
  data() {
    return {
      dialog: true,
      switch1: true,
    };
  },
  methods: {
    isCookieAccepted() {
      const isAccepted = cookieManager.get('AcceptedCookies', Number);
      return isAccepted !== undefined && isAccepted !== '0' && isAccepted !== 0;
    },
    acceptCookie() {
      cookieManager.set('AcceptedCookies', 1);
      this.dialog = false;
    },
    getCookiesUrl() {
      const environment = environmentService.getEnvironment();
      if (environment === 'DEVELOP') {
        return 'https://dev.tmfy.sermescro.ai/cookiespolicy';
      }
      return 'https://tmfy.sermescro.ai/cookiespolicy';
    },
  },
};
</script>
<style scoped>
  .dialog-style {
    margin-top: 0 !important;
  }
</style>
