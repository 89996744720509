<template>
  <v-app>
    <overlay-loader :loading="loading"></overlay-loader>
    <cookies/>
    <v-alert v-if="error" type="error" transition="scale-transition">{{ errorInfo }}</v-alert>
    <v-main>
      <div class="split left tfe-bg-gradient">
        <div class="centered">
          <img
            class="responsive-img"
            alt="Tmfy logo"
            src="../assets/img/logo_white.png"
            height="45px"
            width="150px">
        </div>
      </div>
      <div class="split right">
          <v-container fluid fill-height>
            <v-layout align-center justify-center>
              <v-flex xs12 sm8 md8>
                <v-form>
                  <v-text-field
                    underlined
                    prepend-icon="mdi-folder-key"
                    v-model="licence"
                    label="Licence group*"
                    placeholder="Licence"
                    required
                  ></v-text-field>
                  <v-text-field
                    underlined
                    prepend-icon="mdi-account"
                    v-model="email"
                    label="E-mail*"
                    id="email"
                    placeholder="Email"
                    required>
                  </v-text-field>
                 <v-text-field
                    underlined
                    prepend-icon="mdi-lock"
                    v-model="password"
                    label="Password*"
                    type="password"
                    id="password"
                    placeholder="Password"
                    required
                    @keyup.enter="login">
                  </v-text-field>
                </v-form>
                <v-spacer> </v-spacer>
                <div class="text-center">
                  <v-btn
                    outlined
                    rounded
                    large
                    @click="login"
                    :disabled="formValid">
                    Sign In
                  </v-btn>
                </div>
                <br/>
                <div class="text-center">
                  <a href="/forgot-password" class="tfe-primary-color">Forgot your password?</a>
                </div>
            </v-flex>
          </v-layout>
          <div style="position:absolute; bottom:0; right:0;">
            <p><strong>Version: </strong>{{ version }}</p>
          </div>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import OverlayLoader from '@/components/generic/OverlayLoader.vue';
import { accountServices } from '@/services/accountServices';
import Cookies from '@/components/generic/Cookies.vue';
import VersionJsonFile from '../../../version.json';
import cookieManager from '@/store/cookieManager';

export default {
  components: {
    Cookies,
    OverlayLoader,
  },
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      licence: '',
      error: false,
      errorInfo: 'Correo o contraseña no válidos',
      version: '',
    };
  },
  mounted() {
    this.version = this.getVersionFromProject(VersionJsonFile);
    cookieManager.deleteAll();
  },
  methods: {
    getVersionFromProject(jsonFile) {
      return jsonFile.version;
    },
    login() {
      this.loading = true;
      const data = {
        email: this.email,
        password: this.password,
        licence_name: this.licence,
      };
      accountServices.Login(data)
        .then((response) => {
          this.loading = false;
          cookieManager.set('username', response.data.user.email);
          cookieManager.set('user_id', response.data.user.id);
          cookieManager.set('role', response.data.user.role);
          cookieManager.set('is_staff', response.data.user.is_staff);
          cookieManager.set('is_superuser', response.data.user.is_superuser);
          cookieManager.set('first_name', response.data.user.first_name);
          cookieManager.set('last_name', response.data.user.last_name);
          cookieManager.set('Authorization', response.data.access);
          cookieManager.set('Licence', this.licence);
          cookieManager.set('has_read_all_app_documents', response.data.user.has_read_all_app_documents);
          if (!response.data.user.has_read_all_app_documents) {
            this.$router.push({ name: 'UserProfile' });
          } else {
            this.$router.push('/home');
          }
        })
        .catch((error) => {
          this.loading = false;
          const { code } = error.response.data;
          if (code === 'password_expired') {
            this.errorInfo = `La contraseña ha caducado, a continuación se le
              redirigirá a la página de cambio de contraseña`;
            this.error = true;
            setTimeout(() => {
              this.error = false;
              this.$router.push('/update-expired-password/');
            }, 5000);
          } else if (code === 'no_active_account') {
            this.errorInfo = `Cuenta no activada, revisa la bandeja de entrada en
            caso de no haber activado anteriormente`;
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 5000);
          } else {
            this.errorInfo = 'Email o contraseña incorrecta';
            this.error = true;
            setTimeout(() => {
              this.error = false;
            }, 5000);
          }
          this.error = true;
          setTimeout(() => {
            this.error = false;
            this.error = '';
          }, 3000);
        });
    },
  },
  computed: {
    formValid() {
      return !(this.email !== '' && this.password !== '' && this.licence !== '');
    },
  },
};
</script>
<style lang="scss" scoped>
  .responsive-img {
    width: 100%;
    height: auto;
    margin-left:-20px;
    margin-top:95px;
  }
  .split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
    background-color: white;
  }
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
</style>
